import styled from "@emotion/styled";
import {
  FloatingFocusManager,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  size,
  offset,
  autoUpdate,
} from "@floating-ui/react";
import { transparentize } from "polished";
import { useEffect, useRef, useState } from "react";

import { Icon } from "@smart/itops-icons-dom";
import { isNullOrUndefined } from "@smart/itops-utils-basic";

import { ErrorDisplay } from "./error";
import { InputWrapper, inputStyle, inputWidth } from "./input";
import { optionsWithFallback } from "./item";
import { Label } from "./label";
import { FieldProps, FieldWrapper } from "./wrapper";
import { fieldName } from "../../hooks";

export const ItemList = styled.div`
  display: flex;
  flex-flow: column nowrap;
  background: var(--background);
  border-radius: 3px;
  box-shadow: 1px 1px 3px
    ${(props) => transparentize(0.4, props.theme.palette.disabled.base)};
  padding: ${(props) => props.theme.baseUnit * 0.4}rem;
  overflow: auto;
  z-index: 10;

  .footer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    margin: ${(props) => props.theme.baseUnit * 0.6}rem
      ${(props) => props.theme.baseUnit}rem;

    button {
      border: 0;
      background: none;
      margin: ${(props) => props.theme.baseUnit * 0.1}rem auto
        ${(props) => props.theme.baseUnit * 0.1}rem 0;
      padding: ${(props) => props.theme.baseUnit * 0.2}rem
        ${(props) => props.theme.baseUnit * 2}rem;
      align-self: flex-start;

      color: ${(props) => props.theme.palette.primary.base};
      cursor: pointer;
      font-size: ${(props) => props.theme.fontSize.small};
      outline: none;
      text-decoration: underline;
    }

    .provider {
      flex: 1;
      margin: ${(props) => props.theme.baseUnit * 0.4}rem 0
        ${(props) => props.theme.baseUnit * 0.4}rem auto;
      max-width: ${(props) => props.theme.baseUnit * 4}rem;
    }
  }
`;

export const Item = styled.button`
  background: var(--background);
  border: 0;
  cursor: pointer;
  outline: none;
  flex: 0 0;
  display: flex;
  flex-flow: row;
  align-items: center;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  margin-bottom: ${(props) => props.theme.baseUnit * 0.2}rem;
  padding: ${(props) => props.theme.baseUnit}rem;
  text-align: left;
  width: 100%;
  font-size: ${(props) => props.theme.fontSize.base};

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  &[aria-checked] {
    &::before {
      content: "";
      background: ${(props) => props.theme.palette.background.base};
      border: 4px solid ${(props) => props.theme.palette.background.base};
      display: block;
      box-shadow: 0 0 0 1px
        ${(props) => props.theme.palette.foreground.highlight};
      border-radius: 2px;
      height: ${(props) => props.theme.baseUnit * 1.8}rem;
      width: ${(props) => props.theme.baseUnit * 1.8}rem;
      margin-right: ${(props) => props.theme.baseUnit}rem;
    }
  }
  &[aria-checked="true"] {
    &::before {
      background: ${(props) => props.theme.palette.primary.base};
    }
  }

  &[aria-current="true"] {
    background: ${(props) => props.theme.palette.background.accent};

    &[aria-checked] {
      &::before {
        box-shadow:
          0 0 0 1px ${(props) => props.theme.palette.foreground.highlight},
          0 0 0 4px rgba(0, 0, 0, 0.1);
      }
    }
  }

  &[aria-selected="true"] {
    background: ${(props) => props.theme.palette.secondary.accent};
  }
`;

export const OtherInput = styled.input`
  ${inputStyle}
  ${inputWidth}
  opacity: 1;
  max-height: ${(props) => props.theme.baseUnit * 4}rem;
  transition:
    opacity 0.2s ease,
    margin 0.2s ease,
    padding 0.2s ease,
    max-height 0.2s ease;

  &[aria-disabled="true"] {
    opacity: 0;
    margin: 0;
    padding: 0 ${(props) => props.theme.baseUnit}rem;
    max-height: 0;
    pointer-events: none;
  }
`;

export const useFloatingList = () => {
  const [open, onOpenChange] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const listRef = useRef<Array<HTMLElement | null>>([]);
  const { refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange,
    placement: "bottom",
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(-3),
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width - 5}px`,
            maxHeight: `${availableHeight}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const role = useRole(context, { role: "listbox" });
  const dismiss = useDismiss(context);
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true,
  });
  const { getItemProps, getFloatingProps, getReferenceProps } = useInteractions(
    [role, dismiss, listNav],
  );

  return {
    onOpenChange,
    refs,
    listRef,
    floatingStyles,
    getFloatingProps,
    getItemProps,
    getReferenceProps,
    activeIndex,
    setActiveIndex,
    open,
    context,
  };
};

export const ListField = ({
  field,
  index,
  innerRef,
  value,
  error,
  loading,
  disabled,
  onChange,
  onBlur,
}: FieldProps<HTMLInputElement>) => {
  const items = optionsWithFallback(field.options);
  const otherRef = useRef<HTMLInputElement>(null);
  const [other, setOther] = useState(
    () => !!value && !field.options?.find((o) => o.value === value),
  );
  const [searchTerm, setSearchTerm] = useState(
    (other ? "Other" : value && items.find((i) => i.value === value)?.label) ??
      "",
  );
  const id = fieldName({ field, index });
  const errorId = fieldName({ field, index, suffix: "error" });

  const {
    onOpenChange,
    refs,
    listRef,
    floatingStyles,
    getFloatingProps,
    getItemProps,
    getReferenceProps,
    activeIndex,
    setActiveIndex,
    open,
    context,
  } = useFloatingList();

  const onSelect = (item: { label: string; value: string }) => {
    onOpenChange(false);
    if (value === item.value) {
      onChange(null);
    } else {
      setOther(false);
      onChange(item.value ?? null);
    }
    setSearchTerm(item.label);
    onBlur();
  };
  const toggleOther = () => {
    onOpenChange(false);
    if (!other) {
      setOther(true);
      onChange("");
      setSearchTerm("Other");
      onBlur();
    }
    setTimeout(() => {
      otherRef.current?.focus();
    }, 0);
  };

  const filteredItems = items.filter((item) =>
    item.label.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  useEffect(() => {
    if (isNullOrUndefined(value)) {
      setSearchTerm("");
      setOther(false);
    } else {
      const found = items.find((i) => i.value === value);
      setSearchTerm(found ? found.label : "Other");
      setOther(!found);
    }
  }, [value]);

  useEffect(() => {
    if (!open && value && !searchTerm) {
      setSearchTerm(items.find((i) => i.value === value)?.label);
    }
  }, [open]);

  return (
    <FieldWrapper aria-disabled={disabled} isLoading={loading}>
      <Label {...field} index={index} />
      <InputWrapper
        ref={refs.setReference}
        aria-invalid={!!error}
        aria-errormessage={error ? errorId : undefined}
      >
        <input
          ref={innerRef}
          {...getReferenceProps({
            onClick: () => {
              onOpenChange(true);
            },
            onFocus: () => {
              const selectedIndex =
                value && filteredItems.findIndex((i) => i.value === value);
              setActiveIndex(selectedIndex);
            },
            onKeyDown: (e) => {
              onOpenChange(true);
              if (
                (e.key === "Enter" || e.key === " ") &&
                activeIndex != null &&
                filteredItems[activeIndex]
              ) {
                e.preventDefault();
                onSelect(filteredItems[activeIndex]);
              }
            },
          })}
          id={id}
          name={id}
          aria-invalid={!!error}
          aria-errormessage={error ? errorId : undefined}
          aria-autocomplete="list"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Icon name="angleDown" rotate={open ? 180 : 0} size={12} />
      </InputWrapper>
      {open && (
        <FloatingFocusManager
          context={context}
          initialFocus={-1}
          visuallyHiddenDismiss
        >
          <ItemList
            {...getFloatingProps({
              ref: refs.setFloating,
              style: floatingStyles,
              tabIndex: -1,
            })}
          >
            {filteredItems.map((item, idx) => (
              <Item
                key={item.value}
                id={fieldName({ field, index, suffix: item.value })}
                {...getItemProps({
                  ref(node) {
                    listRef.current[idx] = node;
                  },
                })}
                onClick={() => onSelect(item)}
                tabIndex={-1}
                type="button"
                role="option"
                aria-current={activeIndex === idx}
                aria-selected={item.value === value}
              >
                <span title={item.label}>{item.label}</span>
              </Item>
            ))}
            {field.allowCustomResponse && (
              <Item
                id={fieldName({ field, index, suffix: "other" })}
                {...getItemProps({
                  ref(node) {
                    listRef.current[items.length] = node;
                  },
                })}
                onClick={toggleOther}
                tabIndex={-1}
                type="button"
                role="option"
                aria-current={activeIndex === items.length}
                aria-selected={other}
              >
                <span title="Other">Other</span>
              </Item>
            )}
          </ItemList>
        </FloatingFocusManager>
      )}
      {field.allowCustomResponse && (
        <OtherInput
          ref={otherRef}
          name={fieldName({ field, index, suffix: "other-input" })}
          id={fieldName({ field, index, suffix: "other-input" })}
          aria-label="Other Value"
          type="text"
          value={other ? value || "" : ""}
          aria-disabled={!other}
          onChange={(e) => onChange(e.currentTarget.value)}
          onBlur={onBlur}
        />
      )}
      <ErrorDisplay uri={field.uri} index={index} error={error} />
    </FieldWrapper>
  );
};
