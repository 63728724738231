import styled from "@emotion/styled";

import { documentAutomation } from "@smart/bridge-images-dom";
import { Card, FailureMessage, Illustrations } from "@smart/itops-ui-dom";

import { AutoFillStatus } from "../../types";

type AutoFillIndicatorProps = {
  status: AutoFillStatus | undefined;
  loading?: boolean;
};

const IndicatorWrapper = styled.div`
  position: relative;
  z-index: 85;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  font-size: ${(props) => props.theme.fontSize.base};

  .indicator-contents {
    .title {
      font-size: ${(props) => props.theme.fontSize.subHeading};
      font-weight: bold;
      margin: 1.6rem 0;
    }
  }
`;

export const AutofillIndicator = ({
  status,
  loading,
}: AutoFillIndicatorProps) => {
  if (status !== "filling" && status !== "failed" && !loading) {
    return null;
  }

  return (
    <IndicatorWrapper>
      <Card
        breakPoint="readable"
        flow="row"
        align="center"
        loading={status === "filling" || loading}
      >
        <Illustrations illustrations={[documentAutomation]} active={0} />
        <div className="indicator-contents">
          <h2 className="title">Autofill in progress</h2>
          <div className="description">
            Once complete, review all fields carefully and make any necessary
            edits.
          </div>
          {status === "failed" && (
            <FailureMessage
              action="auto filling form"
              textOverride="Something went wrong. Please try again."
            />
          )}
        </div>
      </Card>
    </IndicatorWrapper>
  );
};
